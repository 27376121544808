import React, { Component, useState } from "react";

import RichBox from "./RichBoxComponent";
import axios from "axios";
import { BASE_URL } from "../../../variables/api";
import Select from "react-select";


// const selectedStyle = {
//   cursor: "pointer",
//   backgroundColor: "#9df3b1 !important",
//   border: "4px solid #098434 !important",
//   borderRadius: "10px !important",
// };



const LearningStandards = (props: any) => {
    /*   state = {
      id:props.id,
      option:props.option ?? "",
      authorNote:props.authorNote ?? "",
    }; */
    // static getDrivedStateFromProps() {

    // }

   
    const [state, setState] = useState({
        targets: props.all_targets?.length > 0 ? [...props.all_targets] : []
    })
    const GetTargets = (standard_id: any) => {
        axios.get(`${BASE_URL}/admin/gettargets.php?id=${standard_id}`).then((response) => {
            setState({ targets: response.data.data })
            props.updateTargets(response.data.data)
        })
    }







    return (
        <div className="col-12 row m-0 p-0 align-items-center align-items-center ">
            <div className="col-sm-6">

                <label className=" text-white ml-1">Learning standard: </label>
                <select
                    className="form-control "
                    name="difficult"
                    id="difficult"
                    defaultValue={props.question?.standardid}
                    onChange={(e: any) => {
                        let standard_id = e.target.value
                        GetTargets(standard_id);
                        props.onChangeStanderd(standard_id)

                    }
                        //props.onChangeDifficult(e.target.value)
                    }

                >
                    <option value="">Select</option>
                    {props.coursestandards?.map((standard: any) => {
                        return <option key={standard.standard_id} value={standard.standard_id}>{standard.learning_outcome}</option>


                    })}

                </select>
            </div>

            <div className="col-sm-6">

                <label className="text-black">Learning targets: </label>
                <Select
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            color: 'black', // Color of text inside the control area (selected option)
                        }),


                        option: (provided, state) => ({
                            ...provided,
                            color: 'black', // Color of the option text
                        }),
                    }}
                    options={state.targets?.map((target: any) => {
                        return { value: target.id, label: target.title };
                    })}
                    isMulti
                    onChange={(e: any) => {
                        let target_ids = e?.map((target: any) => +target.value)
                        props.onChangeTargets(target_ids);


                    }}

                    value={
                        state.targets?.filter((t: any) => props.question?.learning_targets?.includes(+t.id)).map((target: any) => {
                            return { value: target.id, label: target.title };
                        })
                    }

                />

            </div>
        </div>
    )

}

export default LearningStandards;
