import React, { useEffect, useRef, useState } from "react";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { MapSecondsToTime, makeId, objectsAreSame, requestWithAxios } from "../../helpers/func";
import { handleCount, shuffle } from '../../helpers/validation';
import OrderOption, { OptionProps } from "./smallComponents/OrderOption";
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "axios";
import LearningStandards from "./smallComponents/LearningStandards";


// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent
const Order = (props: any) => {
    const [state, setState] = useState<any>(
        {
            id: makeId(10),
            show_objectives: false,
            shuffle_options: false,
            objectives: "",
            feedback: "",
            mode: "",
            type: "OrderComponent",
            total_mark: "",
            score: "0",
            score_mode: "partialMatch",
            header: '',
            headertxt: '',
            required: true,
            options: [
                {
                    id: "Gww36bw4fp",
                    option: "",
                },
                {
                    id: "3wBfSgxArP",
                    option: "",
                },
            ],
            correct_options: [
                {
                    id: "Gww36bw4fp",
                    option: "",
                },

                {
                    id: "3wBfSgxArP",
                    option: "",
                },

            ],
            difficult: "Basic Level",
            cognitive: "Remembering",
            alert: null,
            one_line_options: false,
            spenttime: 0
        }
    );
    const [spenttime, setSpentTime] = useState(0);
    const [intervalId, setIntervalId] = useState<any>(null);
    let count = useRef<any>()
    useEffect(() => {
        if (props.quest != undefined) {

            let Question = { ...props.quest };
            if (typeof props.quest.options == "string") {
                Question.options = JSON.parse(props.quest.options);
                let temp_options = [...Question.options]
                const sortedArray = temp_options.sort((a: any, b: any) => a.order - b.order);
                Question.correct_options = sortedArray;
            } else {

                Question.options = props.quest.options;
                let temp_options = [...Question.options]
                const sortedArray = temp_options.sort((a: any, b: any) => a.order - b.order);
                Question.correct_options = sortedArray;

            }

            if (Question.mode == "examMode") {
                axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
                    setSpentTime(response.data.data);
                    const intervalId = setInterval(() => {
                        setSpentTime((prev: any) => ++prev);
                    }, 1000);
                    setIntervalId(intervalId);
                }).catch((err) => {
                    setSpentTime(0);
                    const intervalId = setInterval(() => {
                        setSpentTime((prev: any) => ++prev);
                    }, 1000);
                    setIntervalId(intervalId);
                }) 

            }
            setState(Question)

        } else {
            setState((prev: any) => ({ ...prev, mode: "editingMode" , all_targets : props.course_standards_targets }))

            Swal.close()
        }
    }, []);


    useEffect(() => {
        return () => {
            if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
                clearInterval(intervalId)
                requestWithAxios(
                    BASE_URL + "student/savespenttime.php",
                    { spenttime: count.current.value, "studentexamquestion_id": state.id }
                );
            }
        }
    }, [intervalId])

    const onSaveQuestion = (updatedState: any) => {

        let optionsrequired = false;

        let require = handleCount(updatedState.header);
        updatedState.options.forEach((o: any) => {
            if (optionsrequired ==false) {
                if (handleCount(o.option) == true) {
                    optionsrequired = true;
                }
            }

        });
        // count of options must be => 2 
        let count = updatedState.options.length;
        if (require ) {
            Swal.fire({
                icon: 'error',
                text: ' Question Header is required',
            }).then(() => {

            })
        } else if (optionsrequired){
            Swal.fire({
                icon: 'error',
                text: 'Options are required',
            }).then(() => {

            })
            
        }
        else if ((state.total_mark == 0) || (state.total_mark == ".")) {
            Swal.fire({
                icon: 'error',
                text: ' Total mark is required',
            }).then(() => {

            })
        } else if (count < 2) {
            Swal.fire({
                icon: 'error',
                text: "Options mustn't be less than two options",
            }).then(() => {

            })
        }
        else {
            let final_options: any = [];
            let temp_state = { ...updatedState }

            temp_state.correct_options.forEach((o: any, index: any) => {
                final_options.push(
                    { id: o.id, option: o.option, order: index }
                );

            });

            if (state.mode == "editingMode" || state.mode == "answeringMode") {

                temp_state.options = shuffle(final_options);
            } else if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
                requestWithAxios(
                    BASE_URL + "student/saveq.php",
                    temp_state
                );

            }


            props.onSaveQuestion(temp_state)
        }
    }



    const addOptionHandler = () => {
        let id = makeId(10);
        let newOp = [
            ...state.options.concat({
                id: id,
                option: "",
                required: true
            }),
        ];
        let newAnsweredOp = [
            ...state.correct_options.concat({
                id: id,
                option: "",
            }),
        ];
        setState((prev: any) => ({ ...prev, options: newOp, correct_options: newAnsweredOp }));
    };

    const onOptionUpdate = (val: OptionProps) => {
        let option = state.options.find((x: any) => x.id === val.id);
        let options = state.options;
        let correct_options = state.correct_options;
        if (option) {
            let index = state.options.indexOf(option);
            options[index] = {
                id: val.id,
                option: val.option,
            };
        }
        let correctOption = state.correct_options.find((x: any) => x.id === val.id);

        if (correctOption) {
            let index = state.correct_options.indexOf(correctOption);
            correct_options[index] = {
                id: val.id,
                option: val.option,
            };
        }

        setState((prev: any) => ({ ...prev, options: options, correct_options: correct_options }));

    };

    const onDeleteOption = (id: any) => {
        let newOp = [...state.options.filter((op: any) => op.id !== id)];
        let newAnsweredOp = [
            ...state.correct_options.filter((op: any) => op.id !== id),
        ];
        setState((prev: any) => ({ ...prev, options: newOp, correct_options: newAnsweredOp }));

    };

    const toggleHandler = () => {
        if (state.mode === "editingMode") {

            setState((prev: any) => ({ ...prev, mode: "answeringMode" }));
        } else {
            setState((prev: any) => ({ ...prev, mode: "editingMode" }));

        }
    };

    const onDrag = (e: any, index: any) => {
        e.dataTransfer.setData("id", index);
    };
    const onDrop = (source: any, destination: any) => {
        if (state.mode == "editingMode" || state.mode == "examMode") {


            let data = JSON.parse(JSON.stringify(state.options));

            if (state.mode == "editingMode") {
                data.splice(destination, 0, data.splice(source, 1)[0])
                let options = [...data]
                let updatedState = { ...state, options }
                setState(updatedState);


            }
            else if (state.mode == "examMode") {
                data.splice(destination, 0, data.splice(source, 1)[0])
                let options = [...data]
                let updatedState = { ...state, options }
                setState(updatedState);

                onSaveQuestion(updatedState)
            }
        } else if (state.mode == "answeringMode") {


            let data = [...state.correct_options];
            data.splice(destination, 0, data.splice(source, 1)[0])

            setState((prev: any) => ({ ...prev, correct_options: [...data] }));
        }
    };
    const onDragOver = (e: any) => {
        e.preventDefault();
    };

    const calculateScore = () => {
        let numberOfQuestions = state.options.length;
        let optionScore = +state.total_mark / numberOfQuestions;
        let studentScore = 0;
        if (state.score_mode == "partialMatch") {
            for (let index = 0; index < state.options.length; index++) {
                if (
                    state.options[index].id == state.correct_options[index]?.id
                ) {
                    studentScore += optionScore;
                }
            }
        } else if (state.score_mode == "exactMatch") {
            for (let index = 0; index < state.options.length; index++) {
                if (
                    state.options[index].id == state.correct_options[index]?.id
                ) {
                    studentScore += optionScore;
                }
            }
            if (studentScore < +state.total_mark) studentScore = 0;
        }

        setState((prev: any) => ({ ...prev, score: studentScore.toFixed(2) }));

    }

    let ops = state.options?.map((op: any, index: any) => {
        return (

            <Draggable draggableId={`draggable-${op.id}`} index={index} key={op.id}>
                {(provieded, snapshot) => (
                    <div
                        ref={provieded.innerRef}
                        {...provieded.draggableProps}
                        {...provieded.dragHandleProps}
                        key={op.id}
                        id={op.id}
                        className={` ${state.one_line_options === true && state.mode == "examMode" ? "col-3" : "col-12"}   m-2 row align-items-baseline`}

                    >
                        <OrderOption
                            correctAnswer={false}
                            style={{ backgroundColor: "green" }}
                            key={op.id}
                            option={op.option}
                            id={op.id}
                            index={index + 1}
                            mode={state.mode}
                            onOptionUpdate={onOptionUpdate}
                            onDeleteOption={onDeleteOption}
                            required={true}
                        />
                    </div>
                )}
            </Draggable>
        );
    });
    let correct_options = state.correct_options?.map((op: any, index: any) => {
        return (
            <Draggable draggableId={`draggable-${op.id}`} index={index} key={op.id}>
                {(provieded, snapshot) => (
                    <div
                        ref={provieded.innerRef}
                        {...provieded.draggableProps}
                        {...provieded.dragHandleProps}
                        key={op.id}
                        id={op.id}
                        className="col-12 m-2 row align-items-baseline"

                    >
                        <OrderOption
                            correctAnswer={false}
                            style={{ backgroundColor: "" }}
                            key={op.id}
                            option={op.option}
                            id={op.id}
                            index={index + 1}
                            mode={state.mode}
                            onOptionUpdate={onOptionUpdate}
                            onDeleteOption={onDeleteOption}
                            required={true}
                        />
                    </div>
                )}
            </Draggable>

        );
    });
    let gradingOptions = state.options?.map((op: any, index: any) => {
        return (
            <div
                key={op?.id}
                className="col-12 row align-items-baseline"

            >
                {op?.id == state.correct_options[index]?.id ? (
                    <div className=" col-12 row align-items-center">
                        <div className="col-12">
                            <OrderOption
                                correctAnswer={false}
                                style={{
                                    backgroundColor: "#9df3b1 ",
                                    border: "4px solid #098434 ", textColor: "white !important"
                                }}
                                key={op.id}
                                option={op.option}
                                id={op.id}
                                index={index + 1}
                                mode={state.mode}
                                onOptionUpdate={onOptionUpdate}
                                onDeleteOption={onDeleteOption}
                                required={true}
                            />
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className=" col-12 row align-items-center">
                            <div className="col">
                                <OrderOption
                                    correctAnswer={false}
                                    style={{ backgroundColor: "#ffe1e2", textColor: "white" }}
                                    key={op.id}
                                    option={op.option}
                                    id={op.id}
                                    index={index + 1}
                                    mode={state.mode}
                                    onOptionUpdate={onOptionUpdate}
                                    onDeleteOption={onDeleteOption}
                                    required={true}
                                />
                            </div>
                            <div className="col-auto ">
                                Correct answer : <i className="fas fa-arrow-right"></i>
                            </div>
                            <div className="col">
                                <OrderOption
                                    correctAnswer={true}
                                    style={{
                                        backgroundColor: "#9df3b1 ",
                                        border: "4px solid #098434 ", textColor: "white !important"
                                    }}
                                    key={state.correct_options[index]?.id}
                                    option={state.correct_options[index]?.option}
                                    id={state.correct_options[index]?.id}
                                    index={index + 1}
                                    mode={state.mode}
                                    onOptionUpdate={onOptionUpdate}
                                    onDeleteOption={onDeleteOption}
                                    required={true}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    });

    return (
        // <div className="mx-5">
        <div className="">
            <div className="row rounded my-3 p-0 ll shadow bg-question">
                {state.mode == "examMode" && (
                    <div className="row col-12">
                        <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
                        </div>
                        <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
                            <input type="hidden" ref={count} value={spenttime} />
                            <div>
                                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-12 row align-items-center mx-auto  bg-question rounded p-3 text-white">
                    <div className="col-12 row justify-content-between">
                        {state.mode == "editingMode" ? (
                            <h3>Question Setup</h3>
                        ) : null}
                        {state.mode == "answeringMode" ? (
                            <h3>Correct answer setup</h3>
                        ) : null}

                        <div className="d-flex flex-column">
                            {state.mode === "editingMode" ||
                                state.mode === "answeringMode" ? (
                                <button
                                    className="btn btn-warning text-white "
                                    onClick={toggleHandler}
                                >
                                    {state.mode == "editingMode"
                                        ? "Go to Answer mode"
                                        : "Go to Question setup mode"}
                                </button>
                            ) : null}
                            <div className="form-group d-flex align-items-center my-3">
                                <span className="col-auto">Total mark : </span>
                                {state.mode === "editingMode" ||
                                    state.mode === "answeringMode" ? (
                                    <input
                                        type="text"

                                        className="col form-control"
                                        name=""
                                        id=""
                                        value={state.total_mark}
                                        onChange={(e) => {
                                            if ((typeof +e.target.value == "number" && +e.target.value > 0) || e.target.value == ".") {
                                                let total_mark = e.target.value

                                                setState((prev: any) => ({ ...prev, total_mark: total_mark }));

                                            }
                                        }
                                        }
                                    />
                                ) : state.mode === "examMode" ||
                                    state.mode === "gradingModeTeacher" ||
                                    state.mode === "gradingModeStudent" ? (
                                    <span>{state.total_mark}</span>
                                ) : null}
                                {state.mode === "gradingModeTeacher" ||
                                    state.mode === "gradingModeStudent" ? (
                                    <>
                                        <span className="border-left ml-2 pl-2 col-auto">Score : </span>
                                        <span>{state.score}</span>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>



                    {
                        ("editingMode" != state?.mode) ? null :
                            <div className="col-12 mb-5 row justify-content-center align-items-center">
                                <div
                                    className="bg-white w-100 text-dark rounded align-items-center p-3"
                                >
                                    Ordering
                                </div>
                            </div>

                    }







                    <div className="col-12 mb-5 row justify-content-center align-items-center">
                        {state.mode === "editingMode" ? (
                            <div className="col-12 w-100 row justify-content-center align-items-center">
                                <RichBox
                                    onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v })); }}
                                    onChangeTxt={(a: any) => { setState((prev: any) => ({ ...prev, headertxt: a })); }}
                                    defaultValue={state.header}
                                    height={"10px"}
                                ></RichBox>
                                {/* <p className="mt-1 bg-danger text-white" style={{fontSize:"1.5rem"}}>{(state.required ? "Warning! Please enter the question before submit":"")}</p> */}
                            </div>
                        ) : state.mode === "examMode" ||
                            state.mode === "answeringMode" ||
                            state.mode === "gradingModeTeacher" ||
                            state.mode === "gradingModeStudent" ? (
                            <div
                                className="bg-white w-100 text-dark rounded align-items-center p-3"
                                dangerouslySetInnerHTML={{ __html: state.header }}
                            ></div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <h5 className="col-12">Options : {state.mode != "editingMode" ? "You should drag option and drop it in another option to swap between them" : ""}  </h5>
                    <div className="col-12 row">
                        <div className={` col-12 row  align-items-center`}>
                            {state.mode === "answeringMode" ? (
                                <DragDropContext
                                    onDragEnd={(param) => {
                                        onDrop(param.source.index, param.destination?.index);
                                    }}>
                                    <Droppable droppableId="drop1">
                                        {(provieded, snapshot) => {
                                            return (
                                                <div className="col-12" ref={provieded.innerRef} {...provieded.droppableProps}>

                                                    {correct_options}
                                                    {provieded.placeholder}

                                                </div>
                                            )
                                        }}
                                    </Droppable>


                                </DragDropContext>
                            ) : null}
                            {state.mode === "gradingModeTeacher" ||
                                state.mode === "gradingModeStudent"
                                ? gradingOptions
                                : null}

                            {state.mode === "editingMode" || state.mode === "examMode" ? (
                                <DragDropContext
                                    onDragEnd={(param) => {
                                        onDrop(param.source.index, param.destination?.index);
                                    }}>
                                    <Droppable droppableId="drop1">
                                        {(provieded) => {

                                            return (
                                                <div className="col-12" ref={provieded.innerRef} {...provieded.droppableProps}>

                                                    {ops}
                                                    {provieded.placeholder}

                                                </div>
                                            )
                                        }}
                                    </Droppable>


                                </DragDropContext>
                            ) : null}
                            {state.mode === "editingMode" ? (
                                <div className="col-12 my-3 ml-5">
                                    <button
                                        className="btn btn-success "
                                        onClick={addOptionHandler}
                                    >
                                        + Options
                                    </button>
                                </div>
                            ) : null}
                        </div>
                        <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />

                        {state.mode === "gradingModeTeacher" ||
                            state.mode === "gradingModeStudent" ? (
                            <div className="col-12 ml-4 row align-items-start align-content-start p-2">
                                <label className="align-self-start">Feedback</label>
                                <textarea
                                    className="form-control align-self-stretch "
                                    name=""
                                    id=""
                                    value={state.feedback}
                                    rows={5}
                                    disabled={
                                        state.mode === "gradingModeStudent" ? true : false
                                    }
                                    onChange={(e) => {
                                        let feedback = e.target.value;
                                        let updatedState = { ...state, feedback }
                                        onSaveQuestion(updatedState)

                                        setState((prev: any) => ({ ...prev, feedback: feedback }));
                                    }
                                    }
                                ></textarea>
                            </div>
                        ) : null}
                    </div>

                    {state.mode === "editingMode" ? (
                        <React.Fragment>
                            <div className="col-12 row my-3 ml-5 ">
                                <input
                                    type="checkbox"
                                    name=""
                                    className="mr-2"
                                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                    id="one_line_options"
                                    checked={state.one_line_options}
                                    onChange={(e) => {

                                        let one_line_options = e.target.checked
                                        setState((prev: any) => ({ ...prev, one_line_options: one_line_options }))
                                    }

                                    }
                                />
                                <label htmlFor="one_line_options" style={{ cursor: "pointer" }}>
                                    Options in one line
                                </label>
                            </div>

                            <div className="col-12"> Scoring Mode :</div>
                            <div className="col-12 row my-3 ml-5">
                                <input
                                    type="radio"
                                    name="match"
                                    className="mr-2"
                                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                    id="exactMatch"
                                    value="exactMatch"
                                    checked={
                                        state.score_mode == "exactMatch" ? true : false
                                    }
                                    onChange={(e) => {
                                        let score_mode = e.target.value;
                                        setState((prev: any) => ({ ...prev, score_mode: score_mode }))
                                    }

                                    }
                                />
                                <label
                                    htmlFor="exactMatch"
                                    style={{ cursor: "pointer" }}
                                    className="mr-5"
                                >
                                    Exact match
                                </label>
                                <input
                                    type="radio"
                                    name="match"
                                    value="partialMatch"
                                    checked={
                                        state.score_mode == "partialMatch" ? true : false
                                    }
                                    className="mr-2"
                                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                    id="partialMatch"
                                    onChange={(e) => {
                                        let score_mode = e.target.value;
                                        setState((prev: any) => ({ ...prev, score_mode: score_mode }))
                                    }
                                    }
                                />
                                <label htmlFor="partialMatch" style={{ cursor: "pointer" }}>
                                    Partial match
                                </label>
                            </div>
                        </React.Fragment>
                    ) : null}
                    {state.mode === "editingMode" ? (
                        <DifficultLevel
                            difficult={state.difficult}
                            cognitive={state.cognitive}
                            onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })) }}
                            onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })) }}
                        />

                    ) : null}
    <div className="col-12 mt-5" style={{ marginTop: "100px" }}></div>
          {state.mode === "editingMode" ? (
            <LearningStandards
            all_targets={state.all_targets?.length > 0? state.all_targets : props.course_standards_targets}
              question={state}
              coursestandards={props.coursestandards}
              onChangeStanderd={(standardid: any) => {
                setState((prev: any) => ({ ...prev, standardid: standardid }));
              }}
              updateTargets = {(all_targets: any) => {
                setState((prev: any) => ({ ...prev, all_targets: all_targets }));
              }}
              onChangeTargets={(learning_targets: any) => {
                setState((prev: any) => ({ ...prev, learning_targets: learning_targets }));
              }}
            ></LearningStandards>

          ) : null}






                    {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
                        <button
                            className="btn btn-warning btn-lg rounded col-3 mt-3"
                            onClick={() => onSaveQuestion(state)}

                        >
                            {" "}
                            Save
                        </button>
                    </div>}
                </div>
            </div>
        </div>
    );


}
export default Order