import React, { useEffect, useRef, useState } from "react";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { MapSecondsToTime, makeId, requestWithAxios } from "../../helpers/func";
import { handleCount } from '../../helpers/validation';
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import axios from "axios";
import LearningStandards from "./smallComponents/LearningStandards";


const TrueOrFalse = (props: any) => {

  const [state, setState] = useState<any>({
    id: makeId(10),
    type: "TrueOrFalse",
    show_objectives: false,
    shuffle_options: false,
    studentexam_id: null,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: "",
    score: "0",
    header: '',
    headertxt: '',
    required: true,
    standardid: "",
    learning_targets: "",
    all_targets : [],
    options: [
      {
        id: makeId(10),
        option: "True",
        authorNote: "",
        selected: false,
        isTrue: false,
      },
      {
        id: makeId(10),
        option: "False",
        authorNote: "",
        selected: false,
        isTrue: false,
      },
    ],
    choose_flag: false,
    difficult: "Basic Level",
    cognitive: "Remembering",
    alert: null,
    spenttime: 0
  });


  let count = useRef<any>();
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    if (props.quest != undefined) {

      let Question = { ...props.quest };
      if (typeof Question.options == "string") {
        Question.options = JSON.parse(Question.options);
      } else {
        Question.options = Question.options;
      }

      if (Question.mode == "examMode") {
        axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
          setSpentTime(response.data.data);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)
        }).catch((err) => {
          setSpentTime(0);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)

        });
      }
      setState(Question)

    } else {
      setState((prev: any) => ({ ...prev, mode: "editingMode" , all_targets : props.course_standards_targets }))
      Swal.close()
    }
  }, []);

  useEffect(() => {
    return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
        clearInterval(intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count.current.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [intervalId])
  const onSaveQuestion = (updatedState: any) => {

    let require = handleCount(updatedState.header);
    if (require) {
      Swal.fire({
        icon: 'error',
        text: ' Question Header is required',
      }).then(() => {

      })
    } else if ((state.total_mark == 0) || (state.total_mark == ".")) {
      Swal.fire({
        icon: 'error',
        text: ' Total mark is required',
      }).then(() => {

      })
    } else if (updatedState.choose_flag == false) {
      Swal.fire({
        icon: 'error',
        text: ' Please select correct answer ',
      }).then(() => {

      })
    } else {

      props.onSaveQuestion(updatedState)
    }

  }


  const onChangeHandler = (value: any, id: any) => {
    let newOp = [
      ...state.options.map((op: any) => {
        if (op.id === id) {
          op.authorNote = value;
        }
        return op;
      }),
    ];
    let updatedState = { ...state, options: newOp }
    setState(updatedState);
  };

  const onClickHandler = (id: any) => {
    let newOp = JSON.parse(JSON.stringify(state.options));


    newOp = [
      ...newOp.map((op: any) => {
        if (op.id === id) {
          op.selected = true;
        } else {
          op.selected = false;
        }
        return op;
      }),
    ];

    let updatedState = { ...state, options: newOp }

    setState(updatedState);

    if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {

      requestWithAxios(
        BASE_URL + "student/saveq.php",
        updatedState
      );

      onSaveQuestion(updatedState)

    }

  };


 
  const toggleHandler = () => {
    if (state.mode === "editingMode") {
      setState((prev: any) => ({ ...prev, mode: "answeringMode" }));
    } else {
      setState((prev: any) => ({ ...prev, mode: "editingMode" }));

    }
  };

  const onChooseCorrect = (id: any) => {
    let newOp = [
      ...state.options.map((op: any) => {
        if (op.id === id) {
          op.isTrue = true;

          setState((prev: any) => ({ ...prev, choose_flag: "true" }));

        } else {
          op.isTrue = false;
        }
        return op;
      }),
    ];
    // this.setState({ options: newOp });
  };



  // : state.mode === "editingMode" ? (
  //   <React.Fragment key={op.id}>
  //     <div className="col-12 normal my-1 d-flex align-items-start p-2 fs-1">
  //       <span className="col">{op.option}</span>
  //     </div>
  //     <div className="col-12  justify-content-center d-flex p-3 fs-1">
  //       <label className="col-auto text-white">Author note : </label>

  //       <input

  //         className="col-8 normal"
  //         value={op.authorNote}
  //         // onChange={onChangeHandler}
  //         onChange={(e) => onChangeHandler(e.target.value, op.id)}
  //       ></input>
  //     </div>
  //   </React.Fragment>
  // )

  let ops = state.options.map((op: any, index: number) => {
    return state.mode === "editingMode" ? (
      <>
        <div
          className={`col-12 normal my-1 d-flex align-items-start p-2 fs-1 ${op.isTrue ? "selected" : "not-selected"
            }`}
          key={index}
        >
          <div className="col-auto mr-2">
            <input
              type="checkbox"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onChange={() => onChooseCorrect(op.id)}
              checked={op.isTrue}
            />
          </div>


          <span
            className="col"
            style={{ cursor: "pointer" }}
            onClick={() => onChooseCorrect(op.id)}
          >
            {op.option}
          </span>
        </div>
        <div className="col-12  justify-content-center d-flex p-3 fs-1">
          <label className="col-auto text-white">Author note : </label>

          <input

            className="col-8 normal"
            value={op.authorNote}

            onChange={(e) => onChangeHandler(e.target.value, op.id)}
          ></input>
        </div>
      </>
    ) : state.mode === "examMode" ? (
      <div
        className={`col-12 normal my-1 d-flex align-items-start p-2 fs-1 ${op.selected ? "choose-it" : "not-selected"
          }`}
        onClick={() => {
          onClickHandler(op.id);
        }}
        key={op.id}
      >
        <span className="col">{op.option}</span>
      </div>
    ) : state.mode === "gradingModeTeacher" ||
      state.mode === "gradingModeStudent" ? (
      <React.Fragment key={op.id}>
        <div
          className={`col-12 normal my-1 d-flex align-items-start p-2 fs-1 ${op.selected && op.isTrue
            ? "correct-answer"
            : op.selected && !op.isTrue
              ? "wrong-answer"
              : !op.selected && op.isTrue
                ? "normal"
                : ""
            }`}
          key={op.id}
        >
          <span className="col">{op.option}</span>
        </div>
        <div className="pl-3 row align-items-center" style={{ width: "90%" }}>
          <label className="col-auto text-white">
            {op.authorNote != "" && `Author note : ${op.authorNote}`}
          </label>
        </div>
      </React.Fragment>
    ) : null;
  });
  return (
    // <div className="mx-5">
    <div className="">

      {state.alert == "null" ? '' : state.alert}

      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={spenttime} />
              <div>
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
          <div className="col-12 row justify-content-between">

            {state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}

            {state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}
            <div className="d-flex flex-column">



              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto text-shadow ">
                  Total mark :
                </span>
                {state.mode === "editingMode" ||
                  state.mode === "answeringMode" ? (
                  <input
                    type="text"

                    className="col form-control"
                    name=""
                    id=""
                    value={state.total_mark}
                    onChange={(e) => {

                      if ((typeof +e.target.value == "number" && +e.target.value > 0) || e.target.value == "" || e.target.value == ".") {
                        let total_mark = e.target.value;
                        setState((prev: any) => ({ ...prev, total_mark: total_mark }));
                      }
                    }
                    }
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <React.Fragment>
                    <span className="text-shadow">{state.total_mark}</span>
                  </React.Fragment>
                ) : null}
                {state.mode === "gradingModeTeacher" || state.mode === "gradingModeStudent" ? (
                  <span className="border-left ml-2 pl-2 text-shadow">
                    Score :  {state.score}
                  </span>
                ) : null}
              </div>
            </div>
          </div>



          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  True/False
                </div>
              </div>

          }







          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => {
                    setState((prev: any) => ({ ...prev, header: v }));
                  }}
                  defaultValue={state.header}
                  height={"10px"}
                ></RichBox>
              </div>
            ) : state.mode === "examMode" ||
              state.mode === "answeringMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <React.Fragment>

                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: state.header }}
                ></div>
              </React.Fragment>
            ) : (
              <div></div>
            )}
          </div>
          <h5 className="col-12">Options :</h5>
          <div className="col-12 row">
            <div
              className={`text-dark col-12 row align-items-center  mx-auto`}
            >
              {ops}
            </div>

            {state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div className="col-12 row align-items-start align-content-start p-2 mx-auto">
                <label className="align-self-start">Feedback</label>
                <textarea
                  className="form-control align-self-stretch "
                  name=""
                  id=""
                  value={state.feedback}
                  rows={5}
                  disabled={
                    state.mode === "gradingModeStudent" ? true : false
                  }
                  onChange={(e) => {
                    let feedback = e.target.value;
                    let updatedState = { ...state, feedback }

                    onSaveQuestion(updatedState)

                    setState((prev: any) => ({ ...prev, feedback: feedback }));

                  }
                  }
                ></textarea>
              </div>
            ) : null}
          </div>
          <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />



          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => {
                setState((prev: any) => ({ ...prev, difficult: difficult }));
              }}
              onChangeCognitive={(cognitive: any) => {
                setState((prev: any) => ({ ...prev, cognitive: cognitive }));
              }}
            ></DifficultLevel>

          ) : null}
          <div className="col-12 mt-5" style={{ marginTop: "100px" }}></div>
          {state.mode === "editingMode" ? (
            <LearningStandards
              all_targets={state.all_targets?.length > 0? state.all_targets : props.course_standards_targets}
              question={state}
              coursestandards={props.coursestandards}
              onChangeStanderd={(standardid: any) => {
                setState((prev: any) => ({ ...prev, standardid: standardid }));
              }}
              updateTargets = {(all_targets: any) => {
                setState((prev: any) => ({ ...prev, all_targets: all_targets }));
              }}
              onChangeTargets={(learning_targets: any) => {
                setState((prev: any) => ({ ...prev, learning_targets: learning_targets }));
              }}
            ></LearningStandards>

          ) : null}



          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12" >
            <button
              className="btn btn-warning btn-lg mt-5 rounded col-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}

        </div>
      </div>
    </div>
  );

}
export default TrueOrFalse