import React, { useEffect, useRef, useState } from "react";

import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { MapSecondsToTime, makeId, requestWithAxios } from "../../helpers/func";
// import { isNullOrUndefined } from '../../helpers/func';
import Option, { OptionProps } from "./smallComponents/Option";
import { handleCount } from '../../helpers/validation';

import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import axios from "axios";
import LearningStandards from "./smallComponents/LearningStandards";

function shuffle(array: any) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

const MCQ = (props: any) => {

  const [state, setState] = useState<any>({

    id: makeId(10),
    type: "MCQ",
    show_objectives: false,
    shuffle_options: false,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: "",
    score: "0",
    header: '',
    is_partial: true,
    headertxt: '',
    required: true,
    alert: null,
    standardid: "",
    learning_targets: "",
    options: [
      {
        authorNote: "",
        id: makeId(10),
        option: "",
        selected: false,
        isTrue: false,

      },
      {
        authorNote: "",
        id: makeId(10),
        option: "",
        selected: false,
        isTrue: false,

      },

    ],
    difficult: "Basic Level",
    cognitive: "Remembering",
    one_line_options: false,
    spenttime: 0
  });


  let count = useRef<any>();
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    if (props.quest != undefined) {
      let Question = { ...props.quest }
      Question.options = typeof props.quest.options == "string" ? JSON.parse(props.quest.options) : props.quest.options
      if (Question.mode === "examMode" && Question.shuffle_options === "true") {
        const shuffled = shuffle([...Question.options]);
        Question.options = shuffled;
      }
      if (Question.mode == "examMode") {

        axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
          setSpentTime(response.data.data);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)
        }).catch((err) => {
          setSpentTime(0);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)

        });

      }
      setState(Question)

    } else {
      setState((prev: any) => ({ ...prev, mode: "editingMode" , all_targets : props.course_standards_targets }))

      Swal.close()
    }
  }, []);

  useEffect(() => {
    return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
        clearInterval(intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count.current.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [intervalId])



  const onSaveQuestion = (updatedState: any) => {
    {
      // check required on options and header
      let optionsrequired = false;
      // check answer mode 
      let answered = false;
      let require = handleCount(updatedState.header);
      updatedState.options.forEach((o: any) => {
        if (optionsrequired === false) {
          if (handleCount(o.option) === true) {
            optionsrequired = true;
          }
        }
        if (answered === false) {
          if (o.isTrue === true) {
            answered = true;
          }
        }
      });
      // count of options must be => 2 
      let count = updatedState.options.length;
      if (require || optionsrequired) {
        Swal.fire({
          icon: 'error',
          text: "Question header and quetions options  are required",
        }).then(() => {

        })
      } else if ((state.total_mark == 0) || (state.total_mark == ".")) {
        Swal.fire({
          icon: 'error',
          text: "Total mark is required",
        }).then(() => {

        })
      } else if (count < 2) {
        Swal.fire({
          icon: 'error',
          text: " Options mustn't be less than two options",
        }).then(() => {

        })
      } else if (answered == false) {
        Swal.fire({
          icon: 'error',
          text: "Please select the correct answer ",
        }).then(() => {

        })
      }
      else {

        props.onSaveQuestion(updatedState)
      }
    }
  }


  const addOptionHandler = () => {
    let newOp = [
      ...state.options.concat({
        id: makeId(10),
        option: "",
        authorNote: "",
        selected: false,
        isTrue: false,
        required: true,
      }),
    ];

    setState((prev: any) => ({ ...prev, options: newOp }))

  };

  const onOptionUpdate = (val: OptionProps) => {
    let option = state.options.find((x: any) => x.id === val.id);
    if (option) {
      let index = state.options.indexOf(option);
      let options = state.options;
      options[index] = {
        id: val.id,
        option: val.option,
        authorNote: val.authorNote,
        selected: false,
        isTrue: val.isTrue,
      };

      setState((prev: any) => ({ ...prev, options: options }))

    }
  };

  const onDeleteOption = (id: any) => {

    let newOp = [...state.options.filter((op: any) => op.id !== id)];

    setState((prev: any) => ({ ...prev, options: newOp }))


  };


  const onClickHandler = (id: any) => {
    let correctArray = state.options.filter((op: any) => op.isTrue == true);
    let isSelectedArr = state.options.filter((op: any) => op.selected == true);

    let newOp = JSON.parse(JSON.stringify(state.options))

    newOp = [
      ...newOp.map((op: any) => {
        if (op.id === id) {
          if (op.selected) {
            op.selected = !op.selected;
          } else if (!op.selected && isSelectedArr.length < correctArray.length) {
            op.selected = !op.selected;
          }
        }
        return op;
      }),
    ];

    let updatedState = { ...state, options: newOp }

    setState(updatedState);
    if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
      onSaveQuestion(updatedState)
      requestWithAxios(
        BASE_URL + "student/saveq.php",
        updatedState
      );

    }

  };
  const onAddScoreHandler = () => {

    if (state.is_partial == "false") {
      var willgetscore = false;
      var atleastonewrong = false;
      for (let i = 0; i < state.options.length; i++) {
        if (
          state.options[i].isTrue == true &&
          state.options[i].selected == true
        ) {
          if (atleastonewrong === false) {
            willgetscore = true;
          } else {
            willgetscore = false;
          }
        }
        if (
          state.options[i].isTrue == false &&
          state.options[i].selected == true
        ) {
          willgetscore = false;
          atleastonewrong = true;
        }
        if (
          state.options[i].isTrue == true &&
          state.options[i].selected == false
        ) {
          willgetscore = false;
          atleastonewrong = true;
        }
      }

      setState((prev: any) => ({ ...prev, score: willgetscore ? state.total_mark : "0" }))

      let score = willgetscore ? state.total_mark : "0";
      let updatedState = { ...state, score };

    }
    else if (state.is_partial == "true") {

      var final_calculated = 0;
      var selected_correct_choices = 0;
      var selected_incorrectchoices = 0;
      var correct_choices = 0;
      var incorrectchoices = 0;
      for (let i = 0; i < state.options.length; i++) {
        if (state.options[i].isTrue == true) {
          correct_choices++;
        }
        if (state.options[i].isTrue == false) {
          incorrectchoices++;
        }
      }
      for (let i = 0; i < state.options.length; i++) {
        if (
          state.options[i].isTrue == true &&
          state.options[i].selected == true
        ) {
          selected_correct_choices++;
        }
        if (
          state.options[i].isTrue == false &&
          state.options[i].selected == true
        ) {
          selected_incorrectchoices++;
        }
      }

      final_calculated =
        (100 / correct_choices) * selected_correct_choices -
        (100 / correct_choices) * selected_incorrectchoices;
      if (final_calculated < 0) {
        final_calculated = 0;
      }


      let score = (final_calculated / 100) * Number(state.total_mark);

      let updatedState = { ...state, score };
      setState((prev: any) => ({ ...prev, score: score }))


    }
  };

  const toggleHandler = () => {
    if (state.mode === "editingMode") {

      setState((prev: any) => ({ ...prev, mode: "answeringMode" }))

    } else {

      setState((prev: any) => ({ ...prev, mode: "editingMode" }))

    }
  };


  const onChooseCorrect = (id: any) => {
    let newOp = [
      ...state.options.map((op: any) => {
        if (op.id === id) {
          op.isTrue = !op.isTrue;
        }
        return op;
      }),
    ];

    setState((prev: any) => ({ ...prev, options: newOp }))

  };


  // let options =typeof this.state.options == "string" ? JSON.parse(this.state.options) : this.state.options ;
  let ops = state.options.map((op: any, index: any) => {
    return (
      <Option
        key={op.id}
        option={op.option}
        id={op.id}
        index={index + 1}
        authorNote={op.authorNote}
        mode={state.mode}
        selected={op.selected}
        isTrue={op.isTrue}
        partialMode={state.is_partial}
        onOptionUpdate={onOptionUpdate}
        onAddScoreHandler={onAddScoreHandler}
        onDeleteOption={onDeleteOption}
        onClickHandler={onClickHandler}
        onChooseCorrect={onChooseCorrect}
        optiontxt={op.option}
        required={true}
        one_line_options={state.one_line_options}
      />
    );
  });

  return (
    // <div className="mx-5">
    <div className="">
      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={spenttime} />
              <div>
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 mx-auto row align-items-center bg-question rounded p-3 text-white">
          <div className="col-12 mx-auto row justify-content-between">

            {state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}

            {state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}

            {/*<button
              className="btn bg-info text-white"
              onClick={stateToJsonGradingMode}
            >
              show
            </button>*/}
            <div className="d-flex flex-column">

              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto">Total mark : </span>
                {state.mode === "editingMode" ||
                  state.mode === "answeringMode" ? (
                  <input
                    type="text"

                    className="col form-control"
                    name=""
                    id=""
                    value={state.total_mark}
                    onChange={(e) => {

                      let total_mark = e.target.value;

                      if ((typeof +total_mark == "number" && +total_mark > 0) || total_mark == "" || total_mark == ".") {
                        setState((prev: any) => ({ ...prev, total_mark: total_mark }))
                      }
                    }
                    }
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <span>{state.total_mark}</span>
                ) : null}
                {state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <>
                    <span className="border-left ml-2 pl-2 col-auto">Score : </span>
                    <span>{state.score}</span>
                  </>
                ) : null}
              </div>
            </div>
          </div>



          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  Multiple Choice
                </div>
              </div>

          }







          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v })); }}

                  defaultValue={state.header}
                  height={"10px"}

                ></RichBox>
              </div>
            ) : state.mode === "examMode" ||
              state.mode === "answeringMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div
                className="bg-white w-100 text-dark rounded align-items-center p-3"
                dangerouslySetInnerHTML={{ __html: state.header }}
              ></div>
            ) : (
              <div></div>
            )}
          </div>
          <h5 className="col-12">Options :</h5>
          <div className="col-12 row">
            <div className={`col-12 row align-items-center mx-auto`}>
              {ops}
            </div>


            {state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success "
                  onClick={addOptionHandler}
                >
                  + Options
                </button>

              </div>
            ) : null}

            {state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div className="col-12 ml-4 row align-items-start align-content-start p-2">
                <label className="align-self-start">Feedback</label>
                <textarea
                  className="form-control align-self-stretch "
                  name=""
                  id=""
                  value={state.feedback}
                  rows={5}
                  disabled={
                    state.mode === "gradingModeStudent" ? true : false
                  }
                  onChange={(e) => {
                    let feedback = e.target.value;
                    let updatedState = { ...state, feedback }
                    onSaveQuestion(updatedState)

                    setState((prev: any) => ({ ...prev, feedback: feedback }))
                  }
                  }
                ></textarea>
              </div>
            ) : null}
          </div>


          <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />


          {state.mode === "editingMode" ? (
            <>

              <div className="ml-4 col-12 row my-3 align-items-start">

                <input
                  type="checkbox"
                  name=""
                  className="mr-2"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  id="one_line_options"
                  checked={state.one_line_options}
                  onChange={(e) => {
                    e.persist();
                    let one_line_options = e.target.checked;

                    setState((prev: any) => ({ ...prev, one_line_options: one_line_options }))

                  }
                  }
                />
                <label htmlFor="one_line_options" style={{ cursor: "pointer" }}>
                  Options in one line
                </label>
              </div>

              <div className="ml-4 col-12 row my-3 align-items-start">
                <input
                  type="checkbox"
                  name=""
                  className="mr-2"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  id="PartialSwitch"
                  checked={state.is_partial}
                  onChange={(e) => {
                    let is_partial = e.target.checked;
                    setState((prev: any) => ({ ...prev, is_partial: is_partial }))
                  }
                  }
                />
                <label htmlFor="PartialSwitch" style={{ cursor: "pointer" }}>
                  Allow Partial Scoring
                </label>
              </div>
            </>
          ) : null}


          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })) }}
              onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })) }}
            />

          ) : null}

          <div className="col-12 mt-5" style={{ marginTop: "100px" }}></div>
          {state.mode === "editingMode" ? (
            <LearningStandards
            all_targets={state.all_targets?.length > 0? state.all_targets : props.course_standards_targets}
              question={state}
              coursestandards={props.coursestandards}
              onChangeStanderd={(standardid: any) => {
                setState((prev: any) => ({ ...prev, standardid: standardid }));
              }}
              updateTargets = {(all_targets: any) => {
                setState((prev: any) => ({ ...prev, all_targets: all_targets }));
              }}
              onChangeTargets={(learning_targets: any) => {
                setState((prev: any) => ({ ...prev, learning_targets: learning_targets }));
              }}
            ></LearningStandards>

          ) : null}
          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12 mt-4">
            <button
              className="btn btn-warning btn-lg rounded col-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
  // }

}
export default MCQ