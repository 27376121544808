import React, { useEffect, useState } from "react";
import { Component } from "react";
import { NavLink, useParams, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { actionCreators as AssessmentActionCreator } from "../../../store/Assessment";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import PublishAssessmentModal from "./PublishStudentsModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../variables/api";
import { ivy_assessment_platform_datatable_tabs_localStorageItem_name, update_ivy_assessment_platform_datatable_tabs_arr } from "../../../helpers/func";
require("datatables.net");
require("../../MyComponents/dataTables.bootstrap4.min");

const Assessments = () => {
  const params = useParams()
  const navigate = useHistory();
  const [modal, setModal] = useState(false)
  const [group_id, setGroup_id] = useState(null)
  const [selectedAssessment_id, setSelectedAssessment_id] = useState(null)
  const { gotdata } = useSelector((state: any) => state.Assessment);
  const { assessment } = useSelector((state: any) => state.Assessment);
  const [assessments, setAssessments] = useState([])
  const [isRetake, setIsRetake] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useDispatch();
  const getExams = (data: any) => {
    Swal.showLoading();
    axios
      .post(
        BASE_URL + "admin/getexams.php", data
      )
      .then((res) => {
        setAssessments(res.data.data)
        setIsLoaded(true)
        Swal.close();
      })
  }

  let ivy_assessment_platform_datatable_obj = {
    page: 0,
    tab_name: "assessments_dashboard",
    search: ""
  }
  let ivy_assessment_platform_datatable_tabs_arr = JSON.parse(localStorage.getItem(ivy_assessment_platform_datatable_tabs_localStorageItem_name) as any)

  const [role, setRole] = useState("")


  // componentDidMount
  useEffect(() => {

    $("#preloader").hide();
    require("datatables.net");
    require("../../MyComponents/dataTables.bootstrap4.min");
    if ((params as any).id) {
      ivy_assessment_platform_datatable_obj.search = "";
      update_ivy_assessment_platform_datatable_tabs_arr(ivy_assessment_platform_datatable_tabs_arr, ivy_assessment_platform_datatable_obj)
    }
    getExams({ id: (params as any).id });

    const token = Cookies.get("quiztoken");
    let decodedToken: any = {};

    decodedToken = jwt_decode(token || "");

    setRole(decodedToken.data?.userRole?.rolename)

  }, [])

  // componentDidUpdate
  useEffect(() => {
    // console.table(assessments)
    if (assessments) {
      if (assessments.length) {
        let this_dt_page = 0;
        let this_dt_search = "";
        if (Array.isArray(ivy_assessment_platform_datatable_tabs_arr)) {
          const foundElement = ivy_assessment_platform_datatable_tabs_arr.find((x: any) => x.tab_name == ivy_assessment_platform_datatable_obj.tab_name);
          if (foundElement) {
            this_dt_page = foundElement.page
            this_dt_search = foundElement.search
          }
        }
        $("#dataTable").DataTable().destroy();
        $('#dataTable').DataTable({
          order: [[4, 'desc']],
          "pageLength": 5,
          "search": {
            "search": this_dt_search
          }
        })
          .page(this_dt_page).draw("page");
      }
    }
  }, [assessments])

  useEffect(() => {
    if (gotdata) {
      navigate.push({
        pathname: "/assessmentcomponent",
        search: '?id=' + assessment.id
      });
    }
  }, [gotdata])


  $('#dataTable').on("search.dt", function () {
    ivy_assessment_platform_datatable_obj.search = $('#dataTable').DataTable().search();
    update_ivy_assessment_platform_datatable_tabs_arr(ivy_assessment_platform_datatable_tabs_arr, ivy_assessment_platform_datatable_obj)
  });

  $("#dataTable").on("draw.dt", function (event, settings) {
    ivy_assessment_platform_datatable_obj.page = settings._iDisplayStart / settings._iDisplayLength;
    update_ivy_assessment_platform_datatable_tabs_arr(ivy_assessment_platform_datatable_tabs_arr, ivy_assessment_platform_datatable_obj)
  });



  const editAssessmentHandelr = (id: any) => {
    navigate.push({
      pathname: "/assessmentcomponent",
      search: '?id=' + id
    });
  };
  const copyAssessmentHandelr = (id: any) => {
    navigate.push({
      pathname: "/assessmentcomponent",
      search: '?id=' + id + "&copy=1"
    });
  };

  const loading = () => {
    Swal.fire({
      html: '<div style="color:blue;"> Loading....... </div>',
      showConfirmButton: false,
    });
  };
  const deleteAssessmentHandelr = async (id: any) => {
    let swal_confirmed = false
    const swal_res = await Swal.fire("Are you sure you want to delete this?", "", "warning").then(
      (res: any) => {
        if (res.isConfirmed) {
          swal_confirmed = true
        }
      }
    )

    if (swal_confirmed) {
      loading()
      $("#dataTable").DataTable().destroy();
      axios.post(BASE_URL + "admin/deleteexam.php", { id: id }).then((res) => {
        Swal.close();


        const tmp1 = [...assessments]
        const tmp = [...tmp1.filter((assessment_X: any) => assessment_X.id != id)]

        setAssessments(tmp)
        $("#dataTable").DataTable({
          order: [[4, 'desc']]
        });
      }).catch((err) => {
        Swal.fire(err.response.data.msg, "", "error");
      })
    }


  };

  const getAssessmentGroups = (id: any, group_id: any) => {
    Swal.showLoading();
    setModal(true)
    setGroup_id(group_id)
    setSelectedAssessment_id(id)
  };

  const PublishAssessment = (Students: any) => {
    Swal.showLoading();
    axios.post(BASE_URL + `${isRetake ? "admin/retake_assessment.php" : "admin/publishassessment.php"}`, { id: selectedAssessment_id, students: Students })
      .then((res) => {
        // setAssessments(res.data.data)
        const tmp1: any = [...assessments]
        tmp1.find((x: any) => x.id === selectedAssessment_id).published = '1'
        const tmp: any = [...tmp1]
        setAssessments(tmp)
        setIsRetake(false)
        Swal.close();
        Swal.fire({
          title: 'Published Successfully',
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: true
        })
      }).catch((err) => {
        Swal.fire(err.response.data.msg, "", "error");
      })

  }
  const pullAssessment = (id: any) => {
    Swal.showLoading();
    axios
      .post(
        BASE_URL + "admin/pullassessment.php",
        { id: id }
      )
      .then((res) => {
        const tmp1: any = [...assessments]
        tmp1.find((x: any) => x.id === id).published = '0'
        const tmp: any = [...tmp1]
        setAssessments(tmp)
        Swal.close();
      }).catch((err) => {
        Swal.fire(err.response.data.msg, "", "error");
      })
  };
  const viewAssessment = (id: any) => {
    navigate.push({
      pathname: "/assessmentViewComponent",
      search: "?id=" + id + "&exammode=examMode",
    });
  };
  const viewAssessmentAsStudent = (id: any) => {
    navigate.push({
      pathname: "/veiwexamasstudent",
      search: '?id=' + id
    });
  };

  const handleModalClose = () => {
    setModal(false)
  }
  const addAssessmentHandler = () => {
    dispatch(AssessmentActionCreator.resetAssessment())
    navigate.push("/assessmentcomponent");
  };
  function makeid2(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const ExportToCsv = (assessment_id: any, assessment_name: any) => {
    Swal.showLoading();
    axios.get(BASE_URL + `admin/analysis/exporttocsv.php?id=${assessment_id}`).then((res) => {
      Swal.close();

      const blob = new Blob([res.data], { type: 'text/csv' });

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${assessment_name}.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);


    }).catch((err) => {
      Swal.fire("Please check your internet and try again ", "", "error");
    })
  }

  const HandleNotify = (id:any) =>{
    Swal.fire({
      title: "Send notification?",
      text: "Are you sure to send mail to pending assessment?",
      icon: 'warning',
      confirmButtonText: 'Okay',
    }).then((x) =>{
      if(x.isConfirmed){
        loading()
        axios.get(BASE_URL + `admin/notify.php?exam_id=${id}`).then((res) => {
          Swal.fire({
            title: "success",
            text: "Emails were sent successfully",
            icon: 'success',
            confirmButtonText: 'Okay',
          });
        }).catch((err) => {
          Swal.fire(err.response.data.msg, "", "error");
        })
      }

    })
 
  }

  return (
    <div>
      {role == "Admin" || role == "Teacher" ? (
        <button
          className="btn bg-info text-white mb-2"
          onClick={addAssessmentHandler}
        >
          Add Assessment
        </button>
      ) : null}
      {
        <div className="table-responsive">

          <table id="dataTable" className="table table-borderd">
            <thead>
              <tr>
                <th>Course name </th>
                <th>Grade Level</th>
                <th>Group name</th>
                <th>Assessment</th>
                <th>Start date</th>

                <th>Published</th>
                <th>Statistics </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {assessments
                ? assessments.map((assessment: any, index: any) => {

                  return (
                    <tr key={assessment.id}>
                      <td>{assessment.crsname}</td>
                      <td>{assessment.gradelevel}</td>
                      <td>{assessment?.group}</td>
                      <td>{assessment?.name}</td>

                      <td>{assessment.startdate}</td>
                      <td>{assessment.published == "1" ? "Yes " : "No"}</td>
                      {/* 
                    <td>
                    {
                      Object.keys(assessment.Statistics).map((k, index) => <div key={index}>{k + " : " + assessment.Statistics[k]}</div>)
                    }
                  </td> */}

                      <td>
                        <div>Graded : {assessment?.GradedCount}</div>
                        <div>Submitted : {assessment?.SubmittedCount}</div>
                        <div>Started : {assessment?.StartedCount}</div>
                        <div>Pending : {assessment?.PendingCount}</div>
                      </td>

                      <td>

                        {assessment?.GradedCount > 0 || assessment?.SubmittedCount > 0 || assessment?.PendingCount > 0 || assessment?.StartedCount > 0 ?
                          null : (
                            <button
                              className="btn bg-info text-white mb-2"
                              onClick={() => {
                                deleteAssessmentHandelr(assessment.id);
                              }}
                            >
                              Delete
                            </button>
                          )
                        }

                        {" "}
                        {

                          role !== "Admin" ? null :
                            <>
                              <NavLink key="4" className="btn bg-info text-white mb-2" to={{
                                pathname: `/dashboard/normaldistribution/${assessment.id}`
                              }}  >
                                Scores Distribution
                              </NavLink>{" "}
                            </>
                        }
                        {" "}
                        {

                          role !== "Admin" ? null :
                            <>
                              <NavLink key="4" className="btn bg-info text-white mb-2" to={{
                                pathname: `/dashboard/analysis/${assessment.id}`
                              }} title="Analysis" >
                                <i className="fa-solid fa-chart-simple"></i>
                              </NavLink>{" "}
                            </>
                        }
                        {

                          assessment.published != "1" ? null :
                            <>
                              <button key="4" className="btn bg-info text-white mb-2" onClick={() => {
                                ExportToCsv(assessment.id, assessment.name)
                              }} title="Analysis" >
                                Csv
                              </button>{" "}
                            </>
                        }
                        {

                          assessment.published != "1" ? null :
                            <>
                              <button key="4" className="btn bg-info text-white mb-2" onClick={() => {
                             
                                HandleNotify(assessment.id);
                              }} title="Send mail" >
                                Notify
                              </button>{" "}
                            </>
                        }
                        <NavLink key="4" className="btn bg-info text-white mb-2" to={{
                          pathname: `/dashboard/StudentsExams/${assessment.id}`
                        }}  >
                          View student answer
                        </NavLink>{" "}
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            copyAssessmentHandelr(assessment.id);
                          }}
                        >
                          Copy
                        </button>{" "}
                        {
                          assessment.published != "1" ? (
                            <button
                              className="btn bg-info text-white mb-2"
                              onClick={() => {
                                editAssessmentHandelr(assessment.id);
                              }}
                            >
                              Edit
                            </button>
                          ) : null
                        }{" "}

                        <span> </span>
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            viewAssessment(assessment.id);
                          }}
                        >
                          Previwe assessment
                        </button>{" "}
                        <span> </span>
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            viewAssessmentAsStudent(assessment.id);
                          }}
                        >
                          View as student
                        </button>{" "}
                        <span> </span>
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            getAssessmentGroups(assessment.id, assessment.coursegroup_id);

                          }}
                        >
                          Publish assessment
                        </button>{" "}
                        {assessment.published == "1" && assessment.GradedCount == 0 && assessment.SubmittedCount == 0 && assessment?.StartedCount == 0 ? (
                          <button
                            className="btn bg-info text-white mb-2"
                            onClick={() => {
                              pullAssessment(assessment.id);
                            }}
                          >
                            Pull assessment
                          </button>
                        ) : null} {" "}

                        {assessment.published == "1" ? (
                          <button
                            className="btn bg-info text-white mb-2"
                            onClick={() => {
                              setIsRetake(true)
                              getAssessmentGroups(assessment.id, assessment.coursegroup_id);

                            }}
                          >
                            Retake assessment same version
                          </button>
                        ) : null}

                      </td>
                    </tr>
                  );
                })
                : " "}
            </tbody>
          </table>
        </div>
      }
      {modal &&
        <PublishAssessmentModal modalOpen={modal} isRetake={isRetake} setIsRetake={() => setIsRetake(false)} selectedAssessment_id={selectedAssessment_id} group_id={group_id} PublishAssessment={PublishAssessment} handleModalClose={handleModalClose} />
      }
    </div>
  )
}


export default Assessments